import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Head from 'react-helmet'

export default function AuthLogin() {
    const [formData, setFormData] = useState({
        fullname: null,
        password: null
    });
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);

    const history = useHistory();

    useEffect(() => {
        let user = localStorage["user"];
        if (user)
            history.push("/")
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { fullname, password } = formData;
        if (!fullname || !password) return;
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/signIn`, formData);
            localStorage.setItem("user", JSON.stringify(res.data.payload.user));
            setErrorMessage(false);
            setSuccessMessage(true);
            setTimeout(() => {
                history.push("/")
            }, 1500);
        } catch (err) {
            setErrorMessage(true);
            console.error(err)
        }
    };

    return (
        <>
            <Head>
                <title>Giriş Yap | Elit Ege Turizm</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <div className="form-body">
                <div className="row">
                    <div className="img-holder">
                        <div className="bg" />
                        <div className="info-holder">
                            <span href="/" style={{ marginLeft: '2px', fontFamily: 'Montserrat, sans serif', color: '#fff' }} className="h2" > Elit Ege Turizm
                            </span>
                        </div>
                    </div>
                    <div className="form-holder">
                        <div className="form-content">
                            <div className="form-items">
                                <div className="page-links">
                                    <a className="active">Giriş Yap</a>
                                </div>
                                {errorMessage && <div className="alert alert-danger" role="alert">
                                    Verilen bilgilerle giriş yapılamıyor.
                                </div>}
                                {successMessage && <div className="alert alert-success" role="alert">
                                    Başarılı şekilde giriş yaptınız.
                                </div>}
                                <form onSubmit={(event) => handleSubmit(event)}>
                                    <input onChange={(e) => setFormData(current => ({ ...current, fullname: e.target.value }))} className="form-control" type="text" name="username" placeholder="Kullanıcı Adı" required />
                                    <input onChange={(e) => setFormData(current => ({ ...current, password: e.target.value }))} className="form-control" type="password" name="password" placeholder="Şifre" required />
                                    <div className="form-button">
                                        <button onClick={(e) => handleSubmit(e)} className="ibtn">Giriş Yap</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}