import React, { useContext, useEffect, useState } from 'react'
import Head from 'react-helmet'
import Header from '../../layout/Header';
import Navigation from '../../layout/Navigation'
import axios from 'axios';
import uedtsContext from '../../context/uedtsContext';

const PersonelEkle = () => {

    const [formData, setFormData] = useState();
    const [alert, setAlert] = useState();
    const [soforler, setSoforler] = useState();
    const { uedts, setUedts } = useContext(uedtsContext);

    const handleSubmit = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/actions/soforKaydet`, {...formData, uetdsSeferReferansNo: formData.uetdsSeferReferansNo ?? uedts.uetdsSeferReferansNo});
            setAlert({ type: "success", message: res.data.message });
        } catch (err) {
            console.error(err)
            setAlert({ type: "error", message: err.response.data.message });
        }
    };

    const loadData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/actions/soforGetir`);
            setSoforler(res.data.payload.soforler);
            const sofor = res.data.payload.soforler?.[0];
            const nameArray = sofor.isimSoyisim.split(" ");
            const lastName = nameArray.pop();
            const firstName = nameArray.join(" ");
            setFormData(current => ({ ...current, tcNo: sofor.tcNo, soforAdi: firstName, soforSoyadi: lastName }));
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const onAracChange = (e) => {
        const sofor = soforler.find((item) => item.aracPlaka == e.target.value);
        const nameArray = sofor.isimSoyisim.split(" ");
        const lastName = nameArray.pop();
        const firstName = nameArray.join(" ");
        setFormData(current => ({ ...current, tcNo: sofor.tcNo, soforAdi: firstName, soforSoyadi: lastName }));
    };

    return (
        <>
            <Head>
                <title>Personel Ekle | Elit Ege Turizm</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <div className="sb2-1">
                        {/*== USER INFO ==*/}
                        <div className="sb2-12" style={{ background: "#171717", color: "#fff" }}>
                            <ul>
                                <li>
                                    <Navigation />
                                </li>
                                <li />
                            </ul>
                        </div>
                    </div>
                    <div className="sb2-2">
                        <div className="form-content" style={{ background: "none" }}>
                            <div className="form-items">
                                <div className="page-links">
                                    <a href='#' className='active'>Personel Ekle</a>
                                </div>
                            </div>
                        </div>
                        {alert &&
                            alert?.type === "success" ?
                            <div className="alert alert-success" role="alert">
                                {alert?.message}
                            </div> :
                            alert?.type === "error" ?
                                <div className="alert alert-danger" role="alert">
                                    {alert?.message}
                                </div> : null
                        }
                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} className="w-400 mw-full">

                            <div className="row">
                                <div className="col-sm-6">
                                    <label htmlFor="area-of-specialization" className="required">Araç Plaka:</label>
                                    <select onChange={(e) => onAracChange(e)} style={{ width: '40%' }} className="form-control" id="area-of-specialization" required >
                                        <option value="" disabled="disabled">Seçim yapınız</option>
                                        {soforler?.map((item) =>
                                            <option key={item.id} value={item.aracPlaka}>{item.aracPlaka}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor='uetdsSeferReferansNo'>Sefer Referans No:</label>
                                        <input defaultValue={uedts.uetdsSeferReferansNo} required type="text" id="uetdsSeferReferansNo" onChange={(e) => setFormData(current => ({ ...current, uetdsSeferReferansNo: e.target.value }))} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor='isimSoyisim'>İsim:</label>
                                        <input value={formData?.soforAdi} type="text" id="soforAdi" onChange={(e) => setFormData(current => ({ ...current, soforAdi: e.target.value }))} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor='isimSoyisim'>Soyisim:</label>
                                        <input value={formData?.soforSoyadi} type="text" id="soforSoyadi" onChange={(e) => setFormData(current => ({ ...current, soforSoyadi: e.target.value }))} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor='tcNo'>TC No:</label>
                                        <input value={formData?.tcNo} type="text" id="tcNo" onChange={(e) => setFormData(current => ({ ...current, tcNo: e.target.value }))} />
                                    </div>
                                </div>
                            </div>

                            <input style={{ background: "#171717" }} className="btn btn-primary" type="submit" value="Gönder" />

                        </form>

                    </div>
                </div>
            </div>
        </>
    )
};

export default PersonelEkle;
