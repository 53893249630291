import { useEffect, useLayoutEffect } from 'react';
import { redirect, useHistory } from "react-router-dom";
import Header from '../layout/Header'
import Navigation from '../layout/Navigation'
import Head from 'react-helmet'

const Homepage = () => {

  const history = useHistory();
  const user = localStorage["user"];
  useEffect(() => {
    if (!user) {
      history.push("/auth-login")
    }
  }, [])

  return (
    <>
      <Head>
        <title>Anasayfa | Elit Ege Turizm</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div>
        <Header />
        {/*== BODY CONTNAINER ==*/}
        <div className="container-fluid">
          <div className="row">
            <div className="sb2-1">
              {/*== USER INFO ==*/}
              <div className="sb2-12" style={{ background: "#171717", color: "#fff" }}>
                <ul>
                  <li>
                    <Navigation />
                  </li>
                  <li />
                </ul>
              </div>
            </div>
            <div className="sb2-2">
              <h4>Menüden işlem seçiniz</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Homepage;
