import React, { useContext, useEffect, useState } from 'react'
import Head from 'react-helmet'
import Header from '../../layout/Header';
import Navigation from '../../layout/Navigation'
import DatePicker, { registerLocale } from 'react-datepicker';
import tr from "date-fns/locale/tr";
import axios from 'axios';
import ulkeler from '../../utilities/ulkeler.json'
import iller from '../../utilities/iller.json'
import ilceler from '../../utilities/ilceler.json'
import uedtsContext from '../../context/uedtsContext';

registerLocale("tr", tr);

const GrupEkle = () => {
    const [grupFormData, setGrupFormData] = useState({
        baslangicUlke: "TR",
        baslangicIl: "48",
        baslangicIlce: "1197",
        bitisUlke: "TR",
        bitisIl: "48",
        bitisIlce: "1197",
        grupUcret: "1000"
    });
    const { uedts, setUedts } = useContext(uedtsContext);
    const [alert, setAlert] = useState();

    const handleGrupSubmit = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/actions/grupEkle`, { ...grupFormData, uetdsSeferReferansNo: grupFormData.uetdsSeferReferansNo ?? uedts.uetdsSeferReferansNo });
            setAlert({ type: "success", message: res.data.message });
            setUedts(current => ({...current, grupId: res.data.payload}));
        } catch (err) {
            console.error(err);
            setAlert({ type: "error", message: err.response.data.message });
        }
    };

    return (
        <>
            <Head>
                <title>Grup Oluştur | Elit Ege Turizm</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <div className="sb2-1">
                        {/*== USER INFO ==*/}
                        <div className="sb2-12" style={{ background: "#171717", color: "#fff" }}>
                            <ul>
                                <li>
                                    <Navigation />
                                </li>
                                <li />
                            </ul>
                        </div>
                    </div>
                    <div className="sb2-2">
                    <div className="form-content" style={{ background: "none" }}>
                            <div className="form-items">
                                <div className="page-links">
                                    <a href='#' className={"active"}>Grup Oluştur</a>
                                </div>
                            </div>
                        </div>
                        {alert &&
                            alert?.type === "success" ?
                            <div className="alert alert-success" role="alert">
                                {alert?.message}
                            </div> :
                            alert?.type === "error" ?
                                <div className="alert alert-danger" role="alert">
                                    {alert?.message}
                                </div> : null
                        }
                        <form onSubmit={(e) => { e.preventDefault(); handleGrupSubmit(); }} className="w-400 mw-full">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor='uetdsSeferReferansNo'>Sefer Referans No:</label>
                                        <input defaultValue={uedts.uetdsSeferReferansNo} required type="text" id="uetdsSeferReferansNo" onChange={(e) => setGrupFormData(current => ({ ...current, uetdsSeferReferansNo: e.target.value }))} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor='grupAdi'>Grup Adı:</label>
                                        <input required type="text" id="grupAdi" onChange={(e) => setGrupFormData(current => ({ ...current, grupAdi: e.target.value }))} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor='grupAciklama'>Grup Açıklama:</label>
                                        <input required type="text" id="grupAciklama" onChange={(e) => setGrupFormData(current => ({ ...current, grupAciklama: e.target.value }))} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Başlangıç Ülke:</label>
                                        <select required onChange={(e) => setGrupFormData(current => ({ ...current, baslangicUlke: e.target.value }))} style={{ width: '40%' }} className="form-control" id="area-of-specialization" >
                                            <option defaultValue={"Türkiye"} disabled="disabled">Seçim yapınız</option>
                                            {ulkeler?.map((item) =>
                                                <option key={item.id} value={item.KOD}>{item.ULKE_ADI.toUpperCase()}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Başlangıç İl:</label>
                                        <select required defaultValue={"48"} disabled={grupFormData.baslangicUlke !== "TR"} onChange={(e) => setGrupFormData(current => ({ ...current, baslangicIl: e.target.value }))} style={{ width: '40%' }} className="form-control" id="area-of-specialization" >
                                            <option>Seçim yapınız</option>
                                            {iller?.map((item) =>
                                                <option selected={item.id === "48"} key={item.id} value={item.id}>{item.label}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Başlangıç İlçe:</label>
                                        <select required disabled={grupFormData.baslangicUlke !== "TR"} onChange={(e) => setGrupFormData(current => ({ ...current, baslangicIlce: e.target.value }))} style={{ width: '40%' }} className="form-control" id="area-of-specialization" >
                                            <option disabled="disabled">Seçim yapınız</option>
                                            {grupFormData.baslangicIl && [...ilceler.filter((item) => item.IL_KODU === grupFormData.baslangicIl)]?.map((item) =>
                                                <option key={item.id} value={item.ILCE_KODU}>{item.ILCE_ADI}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Bitiş Ülke:</label>
                                        <select required onChange={(e) => setGrupFormData(current => ({ ...current, baslangicUlke: e.target.value }))} style={{ width: '40%' }} className="form-control" id="area-of-specialization">
                                            <option defaultValue={"Türkiye"} disabled="disabled">Seçim yapınız</option>
                                            {ulkeler?.map((item) =>
                                                <option key={item.id} value={item.KOD}>{item.ULKE_ADI.toUpperCase()}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Bitiş İl:</label>
                                        <select required defaultValue={"48"} disabled={grupFormData.baslangicUlke !== "TR"} onChange={(e) => setGrupFormData(current => ({ ...current, bitisIlce: e.target.value }))} style={{ width: '40%' }} className="form-control" id="area-of-specialization" >
                                            <option>Seçim yapınız</option>
                                            {iller?.map((item) =>
                                                <option selected={item.id === "48"} key={item.id} value={item.id}>{item.label}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Bitiş İlçe:</label>
                                        <select required disabled={grupFormData.baslangicUlke !== "TR"} onChange={(e) => setGrupFormData(current => ({ ...current, bitisIlce: e.target.value }))} style={{ width: '40%' }} className="form-control" id="area-of-specialization">
                                            <option disabled="disabled">Seçim yapınız</option>
                                            {grupFormData.baslangicIl && [...ilceler.filter((item) => item.IL_KODU === grupFormData.bitisIl)]?.map((item) =>
                                                <option key={item.id} value={item.ILCE_KODU}>{item.ILCE_ADI}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor='grupUcret'>Grup Ücret:</label>
                                        <input required defaultValue={"1000"} type="text" maxLength={20} id="grupUcret" onChange={(e) => setGrupFormData(current => ({ ...current, grupUcret: e.target.value }))} />
                                    </div>
                                </div>
                            </div>

                            <input style={{ background: "#171717" }} className="btn btn-primary" type="submit" value="Gönder" />

                        </form>

                    </div>
                </div>
            </div>
        </>
    )
};

export default GrupEkle;
